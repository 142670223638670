
import { defineComponent, onMounted, computed, ref, watchEffect } from 'vue'

import Policy from '@/core/services/Callable/Policy';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from '@/router/clean';
import { showHide } from '@/store/stateless';
import { store } from "@/store";
import { valueEquals } from 'element-plus';
import { DocumentMutationTypes } from '@/store/enums/Document/Mutations';

export default defineComponent({
    name: 'policy-list',

    setup() {

        const filter = computed(() => {
            return store.state.policy.filter
        })

        const permissions = computed(() => {
            return store.getters.getUserPermissions
        })

        onMounted(async () => {
            setCurrentPageBreadcrumbs("Group Policy Assigned", [{ label: "Policy", value: '' }]);
            store.commit(DocumentMutationTypes.SET_DOCUMENT_LISTS, [])

            const page = router.currentRoute.value.query ? router.currentRoute.value.query.page : 1;
            // if (!store.getters.showByRole([1]) && (permissions.value.is_sales_agent || permissions.value.is_team_leader)) 
            //     filter.value.policy_sales_agent = store.getters.myId
            // if (!store.getters.showByRole([1]) && permissions.value.is_underwritter) 
            //     filter.value.underwriter = store.getters.myId

            if (!store.getters.showByRole([1,9]))
                    filter.value.agent = store.getters.myId


            await getLists(page);
        })

        const getLists = async (e: any) => {
            const page = parseInt(e ? e : 1);

            filter.value.type = 'policy_assigned';
            filter.value.per_page = 50;
            filter.value.keyword = "";
            filter.value.start_date = "";
            filter.value.end_date = "";

            filter.value.page = page;
            filter.value.status = 2;
            filter.value.payment_type = -1;

            router.replace({ query: { page } })
                .catch(error => {
                    if (
                        error.name !== 'NavigationDuplicated' &&
                        !error.message.includes('Avoided redundant navigation to current location')
                    ) {
                        console.log(error)
                    }
                });
            await Policy.groupPolicyLists(filter.value)
        }

        const changeUnderwriter = computed(() => {
            return store.state.policy.transfer_underwriter;
        })

        const transferSale = computed(() => {
            return store.state.policy.transfer_sale;
        })

        const silentReferesh = () => {
            Policy.groupPolicyLists(filter.value)
        }

        watchEffect(() => {
            // if (!transferSale.value.open) {
            //     Policy.groupPolicyLists(filter.value)
            // }

            // if (!changeUnderwriter.value.open) {
            //     Policy.groupPolicyLists(filter.value)
            // }

        })

        return {
            filter,
            showHide,
            silentReferesh
        }
    },
})
