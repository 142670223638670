import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "policies-list" }
const _hoisted_2 = { class: "card border-light health-leads-tasks" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "card-body filters-block" }
const _hoisted_5 = { class: "card border-light mt-5" }
const _hoisted_6 = { class: "card-body card-custom-spacing-tblf-10" }
const _hoisted_7 = { class: "card-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vc_socket = _resolveComponent("vc-socket")!
  const _component_vc_group_invoice_log = _resolveComponent("vc-group-invoice-log")!
  const _component_vc_group_policy_first_layer = _resolveComponent("vc-group-policy-first-layer")!
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_vc_group_policy_second_layer = _resolveComponent("vc-group-policy-second-layer")!
  const _component_vc_group_policy_table = _resolveComponent("vc-group-policy-table")!
  const _component_vc_group_policy_pagination = _resolveComponent("vc-group-policy-pagination")!
  const _component_vc_policy_documents = _resolveComponent("vc-policy-documents")!
  const _component_vc_policy_transfer_sales_agent = _resolveComponent("vc-policy-transfer-sales-agent")!
  const _component_vc_policy_transfer_underwriter = _resolveComponent("vc-policy-transfer-underwriter")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_vc_socket, {
      channel: "health-policy-channel",
      listen: "Health.HealthPolicyCountSocketEvent",
      onAction: _ctx.silentReferesh
    }, null, 8, ["onAction"]),
    _createVNode(_component_vc_group_invoice_log),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_vc_group_policy_first_layer),
            _createVNode(_component_el_divider, { style: {"border-top":"2px solid #dcdfe6"} }),
            _createVNode(_component_vc_group_policy_second_layer)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_vc_group_policy_table)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_vc_group_policy_pagination)
        ])
      ])
    ]),
    _createVNode(_component_vc_policy_documents),
    _createVNode(_component_vc_policy_transfer_sales_agent),
    _createVNode(_component_vc_policy_transfer_underwriter)
  ], 64))
}